import { CustomerFieldType } from "modules/config/deviceConfig/presentation/interfaces/IDeviceProfile";

export enum SystemType {
  TICKET = 1,
  PRE_CASHLESS = 2,
  POST_ACCOUNT = 4,
  WALLET = 7,
}

export enum PrintType {
  TICKET = 1,
  RESUME,
  NONE,
  GROUPED_RESUME,
  SIMPLIFIED,
}

export enum AccountType {
  SHARED = 1,
  INDIVIDUAL,
}


export interface IOperationItem {
  type: SystemType,
  name: string
}

export interface IOperationSelect {
  id: SystemType,
  name: string
}

export interface ICustomerSystemTypeIdentification {
  required: boolean,
  enabled?: boolean,
  id?: string,
  identification: {
      name: string,
      value: CustomerFieldType
  }
}

export interface IDeviceOperationItemValue {
  type: SystemType;
  name: string;
  enabled: boolean;
  printType: PrintType;
  isRemotePrinterEnabled: boolean;
  isPreRechargeEnabled: boolean;
  customerIdentification: ICustomerSystemTypeIdentification[];
  isPrinterLogoEnabled: boolean,
  isPrinterQrCodeEnabled: boolean,
  isPrinterCodeBarEnabled: boolean,
  isPrinterProductEnabled: boolean,
  isPrinterObservationsEnabled: boolean,
  systemLabel?: string,
}
