import { useCallback, useEffect, useState } from "react";
import { IGestaoVendas, IAvailableDay } from "../pages/interfaces/IGestaoVendas";
import { GestaoVendasApi } from "services/api/gestaoVendas/gestaoVendasApi";
import UpdateSegmentos from "modules/gestaoVendas/application/useCases/UpdateSegmentosUseCase";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import GetLocalConfigurationUseCase from "modules/gestaoVendas/application/useCases/GetSegmentosUseCase";
import GetMesas from "modules/gestaoVendas/application/useCases/GetMesasUseCase";
import { IGetConfigDataResponse, IReponseMesas, IReponseOperacoes, IReponseOperadores, OperatiomMethodTypes } from "modules/gestaoVendas/application/interfaces/IGestaoVendasService";
import PostMesas from "modules/gestaoVendas/application/useCases/PostMesasUseCase";
import GetOperacoes from "modules/gestaoVendas/application/useCases/GetOperacoesUseCase";
import PostOperacoes from "modules/gestaoVendas/application/useCases/PostOperacoesUseCase";
import GetOperador from "modules/gestaoVendas/application/useCases/GetOperadorUseCase";
import Utils from "services/utils/Utils";
import { SaveProfileConfigurationUseCase } from "modules/app/application/useCases/SaveProfileConfigurationUseCase";
import AppApi from "services/api/app/AppApi";
import KDSService from "services/api/KDS/KDSService";
import { IAddress } from "components/googleAutocomplete/interfaces/IAddress";
import { GetLocalAddressUseCase } from "modules/kds/application/useCases/GetLocalAddressUseCase";
import { UpdateLocalAddressUseCase } from "modules/kds/application/useCases/UpdateLocalAddressUseCase";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { GetProfileConfigurationUseCase } from "modules/app/application/useCases/GetProfileConfigurationUseCase";
import { parse } from "date-fns";
import { v4 } from 'uuid'
import GetConfigDataUseCase from "modules/gestaoVendas/application/useCases/GetConfigDataUseCase";
import PostConfigDataUseCase from "modules/gestaoVendas/application/useCases/PostConfigDataUseCase";
import { ICatalogListItem } from "modules/catalog/presentation/componentes/catalogListTab/CatalogListTab";
import GetCatalogListsUseCase from "modules/catalog/application/useCases/GetCatalogListsUseCase";
import CatalogService from "services/api/catalog/CatalogService";
import UseConfigValues from "./UseConfigValues";
import { IOperationOptionValue } from "../components/appParams/AppParams";
import PostDefaultProfiles from "modules/gestaoVendas/application/useCases/PostDefaultProfilesUseCase";
import { IResponseConfigLocal } from "modules/gestaoVendas/domain/dtos/IGetSegmentos";
import { IFormErrors } from "../components/vendaOnline/VendaOnlineAppMeep";
import { useQuery } from "react-query";

const initialHour = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date().getDate(),
  0,
  0,
  0
);

const service = AppApi();
const gestaoService = GestaoVendasApi();
const kdsService = KDSService();
const catalogService = CatalogService();

export enum appTypes {
  pre_cashless,
  pos_cashless,
  comanda,
  card,
}

export const UseGestaoVendas = (disableRefetch: boolean = false) => {
  const [responseConfigLocal, setResponseConfigLocal] = useState<IResponseConfigLocal | undefined>();
  const [values, setValues] = useState({} as IGestaoVendas);

  const [dateItens, setDateItens] = useState<IAvailableDay[]>([]);
  const [responseMesas, setResponseMesas] = useState<IReponseMesas[] | undefined>();

  const [responseOperacoes, setResponseOperacoes] = useState<IReponseOperacoes | undefined>();
  const [address, setAddress] = useState<IAddress>();
  const [loadingAddress, setLoadingAddress] = useState(false);
  const [preloading, setPreloading] = useState(false);
  const [stepe, setStepe] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [dataHasBeenLoaded, setDataHasBeenLoaded] = useState(false);

  const [operadorList, setOperadorList] = useState<IReponseOperadores[] | undefined>();
  const [catalogList, setCatalogList] = useState<ICatalogListItem[]>([]);
  const [isLoadingConfig, setIsLoadingConfig] = useState(false);
  const [selectedType, setSelectedType] = useState<appTypes>(appTypes.pre_cashless);
  const [selectedSubType, setSelectedSubType] = useState<number | undefined>(1);
  const [errors, setErrors] = useState<IFormErrors>({} as IFormErrors);

  const { currentLocal } = useLocal();
  const { toast, showLoading, hideLoading } = useUi();
  const { setConfigValues, configValues } = UseConfigValues();

  const [operation, setOperation] = useState<IOperationOptionValue[]>([]);

  const onChangeHandle = useCallback((name: string, value: string) => {
    setValues((prev) => ({ ...prev, [name]: value }));
  }, []);

  useEffect(() => {
    setValues(prev => ({
      ...prev,
      catalogUrl: !prev.catalogUrl ?
        currentLocal!?.name
          .toLowerCase()
          .normalize('NFKD')
          .replace(/[^\w\s]|_/g, '')
          .replace(/\W+(.)/g, function (_, chr) {
            return chr.toUpperCase();
          })
          : prev.catalogUrl
    }))
  }, [currentLocal]);

  const validate = useCallback(() => {
    let hasError = false;
    setErrors({} as IFormErrors);
  
    if (values.logo) {
      const logo = new Image();
      logo.src = values.logo ?? "";
      logo.onerror = () => {
        setErrors(prev => ({ ...prev, logo: "O logo é obrigatório" }));
        hasError = true;
      }      
    }
    if (!values.description) {
      setErrors(prev => ({ ...prev, description: "A descrição é obrigatória" }));
      hasError = true;
    }
    if (!values.catalogUrl) {
      setErrors(prev => ({ ...prev, catalogUrl: "A URL é obrigatória" }));
      hasError = true;
    }
  
    return !hasError;
  }, [values]);

  const onSubmit = useCallback(async (values: IGestaoVendas, throwError = false, checked? : boolean[]) => {
    if(checked?.some(valor => valor === true)){
      if (!address) {
        toast('Configure um endereço', 'error');
        return false;
      }    
    }

    if (!validate()) return;

    if (values.banner?.startsWith('http')) delete values.banner;
    if (values.logo?.startsWith('http')) delete values.logo;

    try {
      await SaveProfileConfigurationUseCase(service, {
        ...values, availableDay: dateItens.filter(x => x.checked).map(x => ({
          ...x,
          startHour: x.startHour.indexOf('T') > -1 ? x.startHour.split('T')[1] : x.startHour,
          endHour: x.endHour.indexOf('T') > -1 ? x.endHour.split('T')[1] : x.endHour,
        }))
      });
      setOpenModal(true);
      setStepe(prev => prev + 1);
      return "ok";
    } catch (err) {
      if (throwError) throw err;
      else toast('Houve um erro ao tentar salvar os dados do estabelecimento.', 'error');
    }
  }, [address, dateItens, toast, validate]);

  useEffect(() => {
    if (!dateItens.length && dataHasBeenLoaded) {
      [0, 1, 2, 3, 4, 5, 6].forEach(x => {
        const days = values.availableDay.filter(y => y.dayOfWeek === x);
        if (!days.length) {
          setDateItens((prev: IAvailableDay[]) => [...prev, {
            dayOfWeek: x,
            checked: false,
            startHour: Utils.toInputDateString(initialHour),
            endHour: Utils.toInputDateString(initialHour),
            listId: v4()
          }])
        } else {
          setDateItens((prev: IAvailableDay[]) => [...prev, ...days.map(y => ({
            ...y,
            checked: true,
            startHour: Utils.toInputDateString(parse(y.startHour, 'HH:mm:ss', new Date())),
            endHour: Utils.toInputDateString(parse(y.endHour, 'HH:mm:ss', new Date())),
            listId: v4()
          }))])
        }
      });
    }
  }, [dataHasBeenLoaded, dateItens.length, values]);

  const handleSaveAddress = async (_address: IAddress) => {
    if (currentLocal) {
      try {
        setLoadingAddress(true);
        await UpdateLocalAddressUseCase(kdsService, { ..._address, localId: currentLocal.id });
        setAddress(_address);
      } finally {
        setLoadingAddress(false);
      }
    }
  }

  const onSubmitHandle = useCallback(() => {
    if (onSubmit) onSubmit(values);
  }, [onSubmit, values]);

  const getProfileConfiguration = useCallback(
    async () => {
      try {
        if (currentLocal) {
          setPreloading(true);
          const response = await GetProfileConfigurationUseCase(service, currentLocal.id);
          setValues({ ...response, localName: currentLocal.name, localId: response.id });
          setDataHasBeenLoaded(true);
        }
      } finally {
        setPreloading(false);
      }
    },
    [currentLocal]
  );

  const getLocalAddress = useCallback(
    async () => {
      if (currentLocal) {
        try {
          const response = await GetLocalAddressUseCase(kdsService, currentLocal?.id);
          setAddress(response);
        } finally {

        }
      }
    },
    [currentLocal]
  );

  const getSegmentos = useCallback(
    async () => {
      if (currentLocal) {
        try {
          const response = await GetLocalConfigurationUseCase(gestaoService, currentLocal.id);
          setResponseConfigLocal(response);
        } finally {

        }
      }
    },
  [currentLocal]);

  const onSubmitSegmentos = useCallback(
    (body) => {
      if (currentLocal) {
        UpdateSegmentos(gestaoService, currentLocal.id, body).then(() => {
          getSegmentos();
        });
      }
    },
    [currentLocal, getSegmentos]
  );

  const getMesas = useCallback(() => {
    if (currentLocal) {
      GetMesas(gestaoService, currentLocal.id).then((response) => {
        setResponseMesas(response);
      });
    }
  }, [currentLocal]);

  const getOperador = useCallback(() => {
    if (currentLocal) {
      GetOperador(gestaoService, currentLocal.id).then((response) => {
        setOperadorList(response);
      });
    }
  }, [currentLocal]);

  const getOperacoes = useCallback(() => {
    if (currentLocal) {
      GetOperacoes(gestaoService, currentLocal.id).then((response) => {
        setResponseOperacoes(response);
      });
    }
  }, [currentLocal]);

  const postMesas = useCallback(
    (qtdMesas: number) => {
      if (currentLocal) {
        const body = {
          LocalClienteId: currentLocal.id,
          Inicio: 1,
          Fim: qtdMesas,
        };
        PostMesas(gestaoService, body).then(() => {
          getMesas()
        });
      }
    },
    [currentLocal, getMesas]);

  const postOperacoes = useCallback(
    (
      ComandaIndividual: boolean,
      Mesa: boolean,
      PrePagoFicha: boolean,
      PrePagoCashless: boolean
    ) => {
      if (currentLocal) {
        const body = {
          LocalClienteId: currentLocal.id,
          ComandaIndividual: ComandaIndividual,
          Mesa: Mesa,
          PrePagoFicha: PrePagoFicha,
          PrePagoCashless: PrePagoCashless,
        };
        PostOperacoes(gestaoService, currentLocal.id, body).then(() => {
          getOperacoes();
        });
        let methods = [] 
        if(Mesa){
          methods.push(OperatiomMethodTypes.OrderPad)
        }
        if(PrePagoFicha){
          methods.push(OperatiomMethodTypes.Ticket)
        }
        PostDefaultProfiles(gestaoService, currentLocal.id, methods)
      }
    },
    [currentLocal, getOperacoes]
  );

  const getConfigData = useCallback(async () => {
    if (currentLocal) {
      try {
        setIsLoadingConfig(true);
        const response = await GetConfigDataUseCase(gestaoService, currentLocal.id)

        let operations = []
        response.allowConsumeCashless && operations.push({ name: 'Cashless', value: "allowConsumeCashless" })
        response.allowConsumeTicketInApp && operations.push({ name: 'Ficha', value: "allowConsumeTicketInApp" })
        setOperation(operations);

        return response;

      } finally {
        setIsLoadingConfig(false);
      }
    }
  }, [currentLocal]);

  const { data: dataConfig, refetch } = useQuery<IGetConfigDataResponse | undefined>(
    'configData', getConfigData,
    {
      refetchInterval: !disableRefetch ? 60000 : false,
      refetchOnWindowFocus: !disableRefetch,
      retry: false,
      enabled: !!currentLocal?.id || !disableRefetch
    }
  );

  const postConfigData = useCallback(async () => {
    if (configValues && currentLocal) {
      try {
        showLoading()
        setIsLoadingConfig(true);
        const allowConsumeCashless = !!operation.find(item => item.value === "allowConsumeCashless")
        const allowConsumeTicketInApp = !!operation.find(item => item.value === "allowConsumeTicketInApp")

        await PostConfigDataUseCase(gestaoService, { ...configValues, localId: currentLocal.id, allowConsumeCashless, allowConsumeTicketInApp });
        refetch();
        toast("Configuração Salva com sucesso", 'success');
        return "ok";
      } catch {
        toast("Ocorreu um erro, tente novamente.", 'error');
      } finally {
        hideLoading()
        setIsLoadingConfig(false);
      }
    }
  }, [configValues, currentLocal, hideLoading, operation, refetch, showLoading, toast]);

  const getCatalogList = useCallback(
    async () => {
      if (currentLocal) {
        const listResponse = await GetCatalogListsUseCase(catalogService, currentLocal.id);
        setCatalogList(listResponse);
      }
    },
    [currentLocal]
  );

  const onConfigChangeHandle = useCallback((
    name: string,
    value: string | boolean | number
  ) => {
    setConfigValues({ ...configValues, [name]: value });
  }, [configValues, setConfigValues]);

  const handleChangeDate = (days: IAvailableDay[]) => {
    setDateItens(days);
  }

  return {
    values,
    setValues,
    onChangeHandle,
    onSubmitHandle,
    dateItens,
    onSubmitSegmentos,
    getSegmentos,
    responseConfigLocal,
    responseMesas,
    postMesas,
    responseOperacoes,
    postOperacoes,
    operadorList,
    handleChangeDate,
    errors,
    setErrors,
    onSubmit,
    address,
    handleSaveAddress,
    loadingAddress,
    stepe,
    setStepe,
    openModal,
    setOpenModal,
    dataConfig,
    getConfigData,
    configValues,
    setConfigValues,
    onConfigChangeHandle,
    postConfigData,
    catalogList,
    isLoadingConfig,
    preloading,
    operation,
    setOperation,
    selectedType,
    setSelectedType,
    selectedSubType,
    setSelectedSubType,
    getCatalogList,
    getMesas,
    getOperacoes,
    getOperador,
    getProfileConfiguration,
    getLocalAddress
  };
};
