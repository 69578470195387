import { useCallback, useEffect, useState } from 'react';
import { CircularProgress, Icon } from '@mui/material';
import { Popover, Switch } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import Layout from "components/layout/presentation/Layout";
import styles from './SaasDigitalMenuPageParams.module.scss';
import Button from 'components/ui/Button/Button';
import UseDimension from 'components/dimension/UseDimension';
import UseConfigValues from '../../hooks/UseConfigValues';
import CloseLocalUseCase from 'modules/gestaoVendas/application/useCases/CloseLocalUseCase';
import RaiseSalesModal from '../../components/raiseSalesModal/RaiseSalesModal';
import SaasCatalogCodes from "../../components/saasCatalogCodes/SaasCatalogCodes";
import SaasOnlineSale from "../../components/saasOnlineSale/SaasOnlineSale";
import { UseGestaoVendas } from '../../hooks/UseGestaoVendas';
import { UseConfigOnlinePayment } from 'modules/onlinePayment/presentation/pages/config/UseConfigOnlinePayment';
import { GestaoVendasApi } from 'services/api/gestaoVendas/gestaoVendasApi';
import { SimpleBanner } from "components/widgets";
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { useUi } from 'contexts/userInterface/UserInterfaceContext';

const service = GestaoVendasApi();

export enum PaymentSidesheetStepEnum {
    paymentForms = 0,
    terms = 1,
    lastStep = 2,
}

const SaasDigitalMenuPage = () => {
    const { toast } = useUi();
    const { dimensions } = UseDimension();
    const { setConfigValues } = UseConfigValues();
    const { currentLocal } = useLocal();
    const { getOnlinePaymentStatus } = UseConfigOnlinePayment();
    const history = useHistory();
    const {
        dataConfig,
        postConfigData,
        isLoadingConfig,
        getSegmentos,
        getCatalogList,
        setValues
    } = UseGestaoVendas();

    const [postSuccess, setPostSuccess] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
    const [isClosed, setIsClosed] = useState(false);
    const [showTipModal, setShowTipModal] = useState(false);

    useEffect(() => {
        setValues(prev => ({ ...prev, localId: currentLocal?.id, localName: currentLocal?.name }));
    }, [currentLocal?.id, currentLocal?.name, setValues]);


    useEffect(() => {
        getSegmentos();
        getCatalogList();
        getOnlinePaymentStatus();
    }, [getCatalogList, getOnlinePaymentStatus, getSegmentos]);

    const closeLocal = async () => {
        if (currentLocal) {
            try {
                await CloseLocalUseCase(service, currentLocal.id, !isClosed);
                setIsClosed(prev => !prev);
            } catch {
                toast(`Ocorreu um erro ao ${isClosed ? 'abrir' : 'fechar'} o estabelecimento.`, 'error');
            }
        }
    }

    useEffect(() => {
        if (currentLocal) {
            const showFullOnlyneSolutions = localStorage.getItem('@showFullOnlyneSolutions');

            const showFullOnlyneSolutionsObj: string[] = JSON.parse(showFullOnlyneSolutions ?? '[]');

            if (!showFullOnlyneSolutionsObj.find(storageItem => currentLocal.id === storageItem)) {
                localStorage.setItem('@showFullOnlyneSolutions', JSON.stringify([...showFullOnlyneSolutionsObj, currentLocal.id]));
            }
        }
    }, [currentLocal]);

    useEffect(() => {
        if (dataConfig) {
            setConfigValues({
                catalogAppId: dataConfig.catalogApp?.id,
                catalogWebId: dataConfig.catalogWeb?.id,
                isActive: dataConfig.isActive,
                allowOnlinePaymentWeb: dataConfig.allowOnlinePaymentWeb,
                enableShoppingCartOnVirtualMenu: dataConfig.enableShoppingCartOnVirtualMenu,
                enableServiceTax: dataConfig.enableServiceTax,
                serviceTaxPercentage: dataConfig.serviceTaxPercentage,
                allowOnlinePaymentApp: dataConfig.allowOnlinePaymentApp && dataConfig.allowOnlinePayment,
                allowConsumeInApp: dataConfig.allowConsumeInApp,
                enableServiceTaxInApp: dataConfig.enableServiceTaxInApp,
                serviceTaxPercentageInApp: dataConfig.serviceTaxPercentageInApp,
                maxCheckInDistanceMeters: dataConfig.maxCheckInDistanceMeters,
                allowManualCheckinWithCardActivation: dataConfig.allowManualCheckinWithCardActivation,
                allowConsumeTicketInApp: dataConfig.allowConsumeTicketInApp,
                isVisibleInApp: dataConfig.isVisibleInApp,
                featuredApp: dataConfig.featuredApp,
                inPlaceEnabled: dataConfig.inPlaceEnabled,
                notificationPhone: dataConfig.notificationPhone,
                allowCheckinWithoutLocation: dataConfig.allowCheckinWithoutLocation,
                allowConsumeCashless: dataConfig.allowConsumeCashless,
                localId: currentLocal?.id!,
                isTemporarilyClosed: dataConfig.isTemporarilyClosed,
                showCustomHome: dataConfig.showCustomHome,
            });

            setIsClosed(dataConfig.isTemporarilyClosed || !dataConfig.isActive);
        }
    }, [currentLocal?.id, dataConfig, setConfigValues]);

    useEffect(() => {
        if (dataConfig?.allowOnlinePayment && dataConfig?.allowOnlinePaymentApp && !localStorage.getItem("showPaymentSuccess")) {
            setPostSuccess(true);
        }
    }, [dataConfig?.allowOnlinePayment, dataConfig?.allowOnlinePaymentApp]);

    const onConfigSubmitHandle = useCallback(async () => {
        postConfigData();
    }, [postConfigData]);

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const handleClose = () => {
        setAnchorEl(undefined);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const getLocalStateButton = () => {
        return (
            <>
                <button onClick={handleClick} className={isClosed ? styles.closed : styles.opened}>
                    <div />
                    <p>{isClosed ? 'Fechado' : 'Aberto'}</p>
                    <Icon>expand_more</Icon>
                </button>

                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    <div className={styles.popoverContainer}>
                        <span>Horário de funcionamento</span>
                        <button onClick={() => history.push({ pathname: '/private/configuracaoCardapioDigital', state: { showDates: true }})}>Editar horário</button>
                        <div />
                        <div>
                            <p>Fechar minha loja</p>
                            <Switch disabled={!dataConfig?.isActive} checked={isClosed} onChange={closeLocal} />
                        </div>
                        <span>Isso interrompe o funcionamento da sua loja.</span>
                    </div>
                </Popover>
            </>
        )
    }

    return (
        <Layout limitedWidth>
            <div className={styles.container}>
                <SimpleBanner
                    title="Cardápio Digital"
                    description="Permita que seus clientes acessem seu cardápio de forma online"
                    imageUrl="/assets/icon/phone-notification.svg"
                    linkText="Aprenda a usar"
                    clickLinkFn={() => setShowTipModal(true)}
                />

                <RaiseSalesModal isOpen={showTipModal} closeFn={() => setShowTipModal(false)} />

                <div className={styles.containerTitle}>
                    <p className={styles.containerTitleText}>💡Ofereça uma experiência interativa aos clientes com fotos, preços, descrições detalhadas e informações nutricionais.</p>

                    <div className={styles.containerTitleActions}>
                        {getLocalStateButton()}
                        <Button style={{ minWidth: '160px' }} onClick={() => history.push('/private/configuracaoCardapioDigital')}>Editar meu perfil</Button>
                    </div>
                </div>

                <SaasCatalogCodes />

                <SaasOnlineSale />

                <Button
                    style={{ width: dimensions.width < 650 ? "100%" : "180px" }}
                    onClick={onConfigSubmitHandle}
                    disabled={isLoadingConfig}
                >
                    {isLoadingConfig ? <CircularProgress size={16} /> : "Salvar"}
                </Button>
            </div>
        </Layout>
    )
}

export default SaasDigitalMenuPage;