import { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './OnlineSolutionsParams.module.scss';
import AppParams from '../../components/appParams/AppParams';
import CatalogCodes from '../../components/catalogCodes/CatalogCodes';
import Button from 'components/ui/Button/Button';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import UseDimension from 'components/dimension/UseDimension';
import { UseGestaoVendas, appTypes } from '../../hooks/UseGestaoVendas';
import UseConfigValues from '../../hooks/UseConfigValues';
import { CircularProgress, Icon } from '@mui/material';
import Sidesheet from 'components/sidesheet/Sidesheet';
import TabView from 'components/tab/TabView';
import PaymentFormsStep from '../../components/paymentSidesheet/paymentFormsStep/PaymentFormsStep';
import PaymentTermsStep from '../../components/paymentSidesheet/paymentTermsStep/PaymentTermsStep';
import PaymentLastStep from '../../components/paymentSidesheet/paymentLastStep/PaymentLastStep';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { UseConfigOnlinePayment } from 'modules/onlinePayment/presentation/pages/config/UseConfigOnlinePayment';
import OnlineSale from '../../components/onlineSale/OnlineSale';
import { Popover, Switch } from '@material-ui/core';
import { GestaoVendasApi } from 'services/api/gestaoVendas/gestaoVendasApi';
import CloseLocalUseCase from 'modules/gestaoVendas/application/useCases/CloseLocalUseCase';
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import RenderIf from 'components/renderIf/RenderIf';
import RaiseSalesModal from '../../components/raiseSalesModal/RaiseSalesModal';
import { useHistory } from 'react-router-dom';

const service = GestaoVendasApi();

export enum PaymentSidesheetStepEnum {
    paymentForms = 0,
    terms = 1,
    lastStep = 2,
}

const cards = [
    {
        type: appTypes.pre_cashless,
        data: [
            {
                id: 1,
                img: '/assets/icon/onlineSolutions/ficha/1.png',
                text: <span>A venda é realizada pelo app e o cliente paga pelo produto antes consumir, gerando <span>uma ficha.</span></span>
            }
        ]
    },
    {
        type: appTypes.card,
        data: [
            {
                id: 2,
                img: '/assets/icon/onlineSolutions/cartao/1.png',
                text: <span>O operador ativa o cartão na POS e o cliente realiza a <b>recarga pelo App.</b></span>
            },
            {
                id: 3,
                img: '/assets/icon/onlineSolutions/cartao/2.png',
                text: <span>O operador ativa o cartão na POS, o cliente realiza a recarga e efetua pedidos pelo App, <b>gerando um QR Code.</b></span>
            },
            {
                id: 4,
                img: '/assets/icon/onlineSolutions/cartao/3.png',
                text: <span>O cliente <b>ativa o cartão diretamente pelo App,</b> e realiza a recarga.</span>
            },
            {
                id: 5,
                img: '/assets/icon/onlineSolutions/cartao/4.png',
                text: <span>O cliente <b>ativa o cartão diretamente pelo App,</b> realiza a recarga e efetua pedidos, gerando um QR Code.</span>
            },
        ]
    },
    {
        type: appTypes.comanda,
        data: [
            {
                id: 6,
                img: '/assets/icon/onlineSolutions/comanda/1.png',
                text: <span>O operador ativa a comanda pela POS e o <b>cliente realiza o pagamento no App.</b></span>
            },
            {
                id: 7,
                img: '/assets/icon/onlineSolutions/comanda/2.png',
                text: <span>O operador ativa a comanda pela POS, <b>o cliente realiza o consumo e efetua o pagamento no App.</b></span>
            },
        ]
    },
    {
        type: appTypes.pos_cashless,
        data: [
            {
                id: 8,
                img: '/assets/icon/onlineSolutions/mesa/1.png',
                text: <span>O operador abre a mesa, <b>os clientes  comandam os pedidos</b> e efetuam o pagamento com divisão no App.</span>
            },
            {
                id: 9,
                img: '/assets/icon/onlineSolutions/mesa/2.png',
                text: <span>O operador abre a mesa e os clientes <b>efetuam o pagamento com divisão no App.</b></span>
            },
        ]
    },
]

export const OnlineSolutionsParams = () => {
    const [isQrCodeVisible, setIsQrCodeVisible] = useState(true);
    const [isAppMeepVisible, setIsAppMeepVisible] = useState(true);
    const [postSuccess, setPostSuccess] = useState(false);
    const [postError, setPostError] = useState(false);
    const [openPaymentSidesheet, setOpenPaymentSidesheet] = useState<string | null>(null);
    const [paymentStep, setPaymentStep] = useState(0);
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const [showHint, setShowHint] = useState(true);
    const [isOnlineSolutionsVisible, setIsOnlineSolutionsVisible] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
    const [isClosed, setIsClosed] = useState(false);
    const [showTipModal, setShowTipModal] = useState(false);
    const [headerTexts, setHeaderTexts] = useState({ title: '', titleHighlight: '', subtitle: '' });

    const { toast } = useUi();
    const { dimensions } = UseDimension();
    const {
        dataConfig,
        postConfigData,
        isLoadingConfig,
        onConfigChangeHandle,
        catalogList,
        responseConfigLocal,
        setOperation,
        operation,
        selectedType,
        setSelectedType,
        selectedSubType,
        setSelectedSubType,
        getSegmentos,
        getCatalogList,
        setValues
    } = UseGestaoVendas();
    const { setConfigValues, configValues } = UseConfigValues();
    const { currentLocal } = useLocal();
    const { postOnlinePayment, getOnlinePaymentStatus, onlinePaymentStatus, loading } = UseConfigOnlinePayment();
    const history = useHistory();

    useEffect(() => {
      setValues(prev => ({ ...prev, localId: currentLocal?.id, localName: currentLocal?.name }))
    }, [currentLocal?.id, currentLocal?.name, setValues]);

    useEffect(() => {
      // saas user texts
      if (currentLocal?.systemIdentifier === 1) {
        setHeaderTexts({
            title: 'Cardápio',
            titleHighlight: 'digital',
            subtitle: 'Atualize em tempo real, reduza o uso de papel e ofereça uma experiência interativa com fotos, preços descrições detalhadas e informações nutricionais.'
        });
      } else {
          // regular user texts
          setHeaderTexts({
            title: 'Soluções',
            titleHighlight: 'online',
            subtitle: 'Transforme a sua presença digital!Seja através do aplicativo Meep, cardápio via QR Code para visualização ou para fazer pedidos online. Melhore a experiência dos seus clientes com múltiplas opções ao alcance de um toque.'
        });
      }
    }, [currentLocal?.systemIdentifier]);


    useEffect(() => {
        getSegmentos();
        getCatalogList();
        getOnlinePaymentStatus();
    }, [getCatalogList, getOnlinePaymentStatus, getSegmentos]);

    const closeLocal = async () => {
        if (currentLocal) {
            try {
                await CloseLocalUseCase(service, currentLocal.id, !isClosed);
                setIsClosed(prev => !prev)
            } catch {
                toast(`Ocorreu um erro ao ${isClosed ? 'abrir' : 'fechar'} o estabelecimento.`, 'error');
            }
        }
    }

    useEffect(() => {
        if (currentLocal) {
            const showFullOnlyneSolutions = localStorage.getItem('@showFullOnlyneSolutions');

            const showFullOnlyneSolutionsObj: string[] = JSON.parse(showFullOnlyneSolutions ?? '[]');

            if (!showFullOnlyneSolutionsObj.find(storageItem => currentLocal.id === storageItem)) {
                localStorage.setItem('@showFullOnlyneSolutions', JSON.stringify([...showFullOnlyneSolutionsObj, currentLocal.id]));
            }
        }
    }, [currentLocal]);

    useEffect(() => {
        if (dataConfig) {
            setConfigValues({
                catalogAppId: dataConfig.catalogApp?.id,
                catalogWebId: dataConfig.catalogWeb?.id,
                isActive: dataConfig.isActive,
                allowOnlinePaymentWeb: dataConfig.allowOnlinePaymentWeb,
                enableShoppingCartOnVirtualMenu: dataConfig.enableShoppingCartOnVirtualMenu,
                enableServiceTax: dataConfig.enableServiceTax,
                serviceTaxPercentage: dataConfig.serviceTaxPercentage,
                allowOnlinePaymentApp: dataConfig.allowOnlinePaymentApp && dataConfig.allowOnlinePayment,
                allowConsumeInApp: dataConfig.allowConsumeInApp,
                enableServiceTaxInApp: dataConfig.enableServiceTaxInApp,
                serviceTaxPercentageInApp: dataConfig.serviceTaxPercentageInApp,
                maxCheckInDistanceMeters: dataConfig.maxCheckInDistanceMeters,
                allowManualCheckinWithCardActivation: dataConfig.allowManualCheckinWithCardActivation,
                allowConsumeTicketInApp: dataConfig.allowConsumeTicketInApp,
                isVisibleInApp: dataConfig.isVisibleInApp,
                featuredApp: dataConfig.featuredApp,
                inPlaceEnabled: dataConfig.inPlaceEnabled,
                notificationPhone: dataConfig.notificationPhone,
                allowCheckinWithoutLocation: dataConfig.allowCheckinWithoutLocation,
                allowConsumeCashless: dataConfig.allowConsumeCashless,
                localId: currentLocal?.id!,
                isTemporarilyClosed: dataConfig.isTemporarilyClosed,
                showCustomHome: dataConfig.showCustomHome
            });

            setIsClosed(dataConfig.isTemporarilyClosed || !dataConfig.isActive);
        }
    }, [currentLocal?.id, dataConfig, setConfigValues]);

    useEffect(() => {
        switch (selectedSubType) {
            case 1:
                setConfigValues({
                    ...configValues,
                    isActive: true,
                    allowOnlinePaymentApp: dataConfig?.allowOnlinePayment ? true : configValues.allowOnlinePaymentApp,
                    allowConsumeInApp: true,
                    enableServiceTaxInApp: true,
                    inPlaceEnabled: true,
                    allowManualCheckinWithCardActivation: false,
                    allowConsumeCashless: false,
                    allowConsumeTicketInApp: true,
                    isVisibleInApp: true,
                    notificationPhone: true,
                    allowCheckinWithoutLocation: false,
                });
                setOperation([
                    {
                        value: "allowConsumeTicketInApp",
                        name: "Ficha"
                    }
                ]);
                break;
            case 2:
                setConfigValues({
                    ...configValues,
                    isActive: true,
                    allowOnlinePaymentApp: dataConfig?.allowOnlinePayment ? true : configValues.allowOnlinePaymentApp,
                    allowConsumeInApp: true,
                    enableServiceTaxInApp: true,
                    inPlaceEnabled: true,
                    allowManualCheckinWithCardActivation: false,
                    allowConsumeCashless: true,
                    allowConsumeTicketInApp: false,
                    isVisibleInApp: true,
                    notificationPhone: true,
                    allowCheckinWithoutLocation: false,
                });
                setOperation([
                    {
                        value: "allowConsumeCashless",
                        name: "Cashless"
                    }
                ]);
                break;
            case 3:
                setConfigValues({
                    ...configValues,
                    isActive: true,
                    allowOnlinePaymentApp: dataConfig?.allowOnlinePayment ? true : configValues.allowOnlinePaymentApp,
                    allowConsumeInApp: true,
                    enableServiceTaxInApp: true,
                    inPlaceEnabled: true,
                    allowManualCheckinWithCardActivation: false,
                    allowConsumeCashless: true,
                    allowConsumeTicketInApp: false,
                    isVisibleInApp: true,
                    notificationPhone: true,
                    allowCheckinWithoutLocation: false,
                });
                setOperation([
                    {
                        value: "allowConsumeCashless",
                        name: "Cashless"
                    }
                ]);
                break;
            case 4:
                setConfigValues({
                    ...configValues,
                    isActive: true,
                    allowOnlinePaymentApp: dataConfig?.allowOnlinePayment ? true : configValues.allowOnlinePaymentApp,
                    allowConsumeInApp: false,
                    enableServiceTaxInApp: true,
                    inPlaceEnabled: true,
                    allowManualCheckinWithCardActivation: true,
                    allowConsumeCashless: true,
                    allowConsumeTicketInApp: false,
                    isVisibleInApp: true,
                    notificationPhone: true,
                    allowCheckinWithoutLocation: false,
                });
                setOperation([
                    {
                        value: "allowConsumeCashless",
                        name: "Cashless"
                    }
                ]);
                break;
            case 5:
                setConfigValues({
                    ...configValues,
                    isActive: true,
                    allowOnlinePaymentApp: dataConfig?.allowOnlinePayment ? true : configValues.allowOnlinePaymentApp,
                    allowConsumeInApp: true,
                    enableServiceTaxInApp: true,
                    inPlaceEnabled: true,
                    allowManualCheckinWithCardActivation: true,
                    allowConsumeCashless: true,
                    allowConsumeTicketInApp: false,
                    isVisibleInApp: true,
                    notificationPhone: true,
                    allowCheckinWithoutLocation: false,
                });
                setOperation([
                    {
                        value: "allowConsumeCashless",
                        name: "Cashless"
                    }
                ]);
                break;
            case 6:
                setConfigValues({
                    ...configValues,
                    isActive: true,
                    allowOnlinePaymentApp: dataConfig?.allowOnlinePayment ? true : configValues.allowOnlinePaymentApp,
                    allowConsumeInApp: false,
                    enableServiceTaxInApp: true,
                    inPlaceEnabled: true,
                    allowManualCheckinWithCardActivation: false,
                    allowConsumeCashless: true,
                    allowConsumeTicketInApp: false,
                    isVisibleInApp: true,
                    notificationPhone: true,
                    allowCheckinWithoutLocation: false,
                });
                setOperation([
                    {
                        value: "allowConsumeCashless",
                        name: "Cashless"
                    }
                ]);
                break;
            case 7:
                setConfigValues({
                    ...configValues,
                    isActive: true,
                    allowOnlinePaymentApp: dataConfig?.allowOnlinePayment ? true : configValues.allowOnlinePaymentApp,
                    allowConsumeInApp: true,
                    enableServiceTaxInApp: true,
                    inPlaceEnabled: true,
                    allowManualCheckinWithCardActivation: false,
                    allowConsumeCashless: true,
                    allowConsumeTicketInApp: false,
                    isVisibleInApp: true,
                    notificationPhone: true,
                    allowCheckinWithoutLocation: false,
                });
                setOperation([
                    {
                        value: "allowConsumeCashless",
                        name: "Cashless"
                    }
                ]);
                break;
            case 8:
                setConfigValues({
                    ...configValues,
                    isActive: true,
                    allowOnlinePaymentApp: dataConfig?.allowOnlinePayment ? true : configValues.allowOnlinePaymentApp,
                    allowConsumeInApp: true,
                    enableServiceTaxInApp: true,
                    inPlaceEnabled: true,
                    allowManualCheckinWithCardActivation: false,
                    allowConsumeCashless: true,
                    allowConsumeTicketInApp: false,
                    isVisibleInApp: true,
                    notificationPhone: true,
                    allowCheckinWithoutLocation: false,
                });
                setOperation([
                    {
                        value: "allowConsumeCashless",
                        name: "Cashless"
                    }
                ]);
                break;
            case 9:
                setConfigValues({
                    ...configValues,
                    isActive: true,
                    allowOnlinePaymentApp: dataConfig?.allowOnlinePayment ? true : configValues.allowOnlinePaymentApp,
                    allowConsumeInApp: false,
                    enableServiceTaxInApp: true,
                    inPlaceEnabled: true,
                    allowManualCheckinWithCardActivation: false,
                    allowConsumeCashless: true,
                    allowConsumeTicketInApp: false,
                    isVisibleInApp: true,
                    notificationPhone: true,
                    allowCheckinWithoutLocation: false,
                });
                setOperation([
                    {
                        value: "allowConsumeCashless",
                        name: "Cashless"
                    }
                ]);
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSubType, setConfigValues])

    useEffect(() => {
        if (dataConfig?.allowOnlinePayment && dataConfig?.allowOnlinePaymentApp && !localStorage.getItem("showPaymentSuccess")) {
            setPostSuccess(true);
        }
    }, [dataConfig?.allowOnlinePayment, dataConfig?.allowOnlinePaymentApp]);

    const onConfigSubmitHandle = useCallback(async () => {
        postConfigData();
    }, [postConfigData]);

    const handleFooterButton = () => {
        if (paymentStep === PaymentSidesheetStepEnum.paymentForms) {
            return "Continuar";
        }
        if (paymentStep === PaymentSidesheetStepEnum.terms) {
            return "Enviar solicitação";
        }
        return "Fechar";
    };

    const handleShowTabView = useCallback(() => {
        if (paymentStep === PaymentSidesheetStepEnum.lastStep) {
            return undefined;
        }
        return (
            <TabView
                index={paymentStep || PaymentSidesheetStepEnum.paymentForms}
                tabs={[
                    {
                        label: "Formas de Pagamento",
                        value: PaymentSidesheetStepEnum.paymentForms,
                    },
                    {
                        label: "Termos de uso",
                        value: PaymentSidesheetStepEnum.terms,
                    },
                ]}
                onChangeTab={(tab) => setPaymentStep(tab.value)}

            />
        );
    }, [paymentStep, setPaymentStep]);

    const paymentStepsContent = useMemo(() => {
        switch (paymentStep) {
            case PaymentSidesheetStepEnum.paymentForms:
                return (
                    <PaymentFormsStep />
                );
            case PaymentSidesheetStepEnum.terms:
                return (
                    <PaymentTermsStep
                        acceptedTerms={acceptedTerms}
                        setAcceptedTerms={setAcceptedTerms}
                    />
                );
            default:
                return (
                    <PaymentLastStep />
                )
        }
    }, [paymentStep, acceptedTerms]);

    const handleCloseSidesheet = () => {
        setPaymentStep(0);
        setOpenPaymentSidesheet(null);
    };

    const handleContinueButton = async () => {
        if (paymentStep === PaymentSidesheetStepEnum.lastStep) {
            return handleCloseSidesheet();
        }
        if (paymentStep === PaymentSidesheetStepEnum.terms) {
            const res = await postOnlinePayment();
            if (res === "ok") {
                getOnlinePaymentStatus();
                setPostError(true);
                setPaymentStep(paymentStep + 1);
            }
            return;
        }
        setPaymentStep(paymentStep + 1);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const handleClose = () => {
        setAnchorEl(undefined);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const getLocalStateButton = () => {
        return (
            <>
                <button onClick={handleClick} className={isClosed ? styles.closed : styles.opened}>
                    <div />
                    <p>{isClosed ? 'Fechado' : 'Aberto'}</p>
                    <Icon>expand_more</Icon>
                </button>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    <div className={styles.popoverContainer}>
                        <span>Horário de funcionamento</span>
                        <button onClick={() => history.push({ pathname: '/private/configuracaoCardapioDigital', state: { showDates: true }})}>Editar horário</button>
                        <div />
                        <div>
                            <p>Fechar minha loja</p>
                            <Switch disabled={!dataConfig?.isActive} checked={isClosed} onChange={closeLocal} />
                        </div>
                        <span>Isso interrompe o funcionamento da sua loja.</span>
                    </div>
                </Popover></>
        )
    }

    return (
        <>
            <div className={styles.container}>
                <RenderIf condition={currentLocal?.systemIdentifier === 1}>
                    <div className={styles.digitalMenuBanner}>
                        <div>
                            <img className={styles.digitalMenuBannerImage} src="/assets/img/digital-menu-small-phone.png" alt="digital-menu-small-phone" />
                        </div>

                        <div className={styles.digitalMenuBannerTextWrapper}>
                            <p className={styles.digitalMenuBannerText}>
                                <span className={styles.digitalMenuBannerTextUnderline}>Aumente suas vendas</span> com o cardápio <b>digital</b>
                            </p>

                            <button className={styles.digitalMenuBannerButton} data-small onClick={() => setShowTipModal(true)}>
                                <PlayCircleOutlineIcon />
                                <span>Aprenda como usar</span>
                            </button>
                        </div>

                        <div className={styles.digitalMenuBannerLinkWrapper}>
                            <button className={styles.digitalMenuBannerButton} role="link" onClick={() => history.push('/private/catalog')}>Cadastrar produto</button>
                        </div>
                    </div>

                    <RaiseSalesModal isOpen={showTipModal} closeFn={() => setShowTipModal(false)} />
                </RenderIf>

                <div className={styles.containerTitle}>
                    <h1>{headerTexts.title} <span>{headerTexts.titleHighlight}</span></h1>
                    <div>
                        {getLocalStateButton()}
                        {/* <Button variant="outlined" onClick={onChangeDates}>Horário de funcionamento</Button> */}
                        <Button onClick={() => history.push('/private/configuracaoCardapioDigital')}>Editar meu perfil</Button>
                    </div>
                </div>
                <p>{headerTexts.subtitle}</p>

                <RenderIf condition={currentLocal?.systemIdentifier !== 1}>
                    <header>
                        <div className={styles.headerTitle}>
                            <Icon className={styles.headerIcon}>settings_outlined</Icon>
                            <b>Configure mais rápido:</b>
                            <span className={styles.text}>selecione como você quer vender o app?</span>
                        </div>

                        <div className={styles.typesContainer}>
                            <button onClick={() => { setSelectedType(appTypes.pre_cashless); setSelectedSubType(undefined) }} className={selectedType === appTypes.pre_cashless ? styles.selected : ''}>Ficha</button>
                            <button onClick={() => { setSelectedType(appTypes.card); setSelectedSubType(undefined) }} className={selectedType === appTypes.card ? styles.selected : ''}>Cartão de recarga</button>
                            <button onClick={() => { setSelectedType(appTypes.comanda); setSelectedSubType(undefined) }} className={selectedType === appTypes.comanda ? styles.selected : ''}>Comanda</button>
                            <button onClick={() => { setSelectedType(appTypes.pos_cashless); setSelectedSubType(undefined) }} className={selectedType === appTypes.pos_cashless ? styles.selected : ''}>Mesa</button>
                        </div>
                        {
                            showHint &&
                            <div className={styles.hintContainer}>
                                {
                                    selectedType === appTypes.pre_cashless ?
                                        <span>A venda é realizada no app e o cliente <b>paga pelo produto antes</b> de consumir.</span> :
                                        selectedType === appTypes.card ?
                                            <span>O cliente <b>recarrega o cartão</b> de consumo e realiza o pedido no balcão ou na mesa.</span> :
                                            selectedType === appTypes.comanda ?
                                                <span>O cliente recebe uma comanda individual e <b>realiza o pagamento ao final do atendimento.</b></span> :
                                                <span>O atendimento é feito pelo garçom, na mesa, e o pagamento é realizado no <b>fechamento da conta.</b></span>
                                }
                                <button className={styles.closeHintButton} onClick={() => setShowHint(false)}>
                                    <Icon>close</Icon>
                                </button>
                            </div>
                        }
                        <div className={styles.cardContainer}>
                            {
                                cards.find(x => x.type === selectedType)?.data.map(x => (
                                    <button className={selectedSubType === x.id ? styles.selected : ''} onClick={() => setSelectedSubType(x.id)}>
                                        <img src={x.img} alt="" />
                                        {x.text}
                                    </button>
                                ))
                            }
                        </div>
                    </header>

                    <AppParams
                        isAppMeepVisible={isAppMeepVisible}
                        setIsAppMeepVisible={setIsAppMeepVisible}
                        postSuccess={postSuccess}
                        setPostSuccess={setPostSuccess}
                        postError={postError}
                        setPostError={setPostError}
                        onConfigChangeHandle={onConfigChangeHandle}
                        catalogList={catalogList}
                        responseConfigLocal={responseConfigLocal}
                        setOperation={setOperation}
                        operation={operation}
                        setOpenPaymentSidesheet={setOpenPaymentSidesheet}
                        onlinePaymentStatus={onlinePaymentStatus}
                        dataConfig={dataConfig}
                    />
                </RenderIf>

                <CatalogCodes
                    isQrCodeVisible={isQrCodeVisible}
                    setIsQrCodeVisible={setIsQrCodeVisible}
                />

                <OnlineSale
                    isOnlineSolutionsVisible={isOnlineSolutionsVisible}
                    setIsOnlineSolutionsVisible={setIsOnlineSolutionsVisible}
                    catalogId={configValues?.catalogWebId}
                    catalogUrl={dataConfig?.catalogUrl}
                />

                <Button
                    style={{ width: dimensions.width < 650 ? "100%" : "180px" }}
                    onClick={onConfigSubmitHandle}
                    disabled={isLoadingConfig}
                >
                    {isLoadingConfig ? <CircularProgress size={16} /> : "Salvar"}
                </Button>
            </div>

            <Sidesheet
                isLoading={loading}
                open={openPaymentSidesheet}
                onClose={handleCloseSidesheet}
                title={
                    <h2>
                        Pagamento <b>online</b>
                    </h2>
                }
                content={
                    paymentStep !== 3 ? (
                        <>{paymentStepsContent}</>
                    ) : (
                        <div />
                    )
                }
                currentStep={paymentStep}
                totalSteps={2}
                continueButton={handleFooterButton()}
                handleContinueButton={handleContinueButton}
                disableContinueButton={paymentStep === PaymentSidesheetStepEnum.terms && !acceptedTerms}
                cancelButton={paymentStep !== PaymentSidesheetStepEnum.lastStep}
                tabView={handleShowTabView()}
                notTotalHeight={paymentStep === PaymentSidesheetStepEnum.lastStep}
            />
        </>
    )
}