export interface IPaymentMethodItem {
  type: PaymentType; //paymentEnum
  name: string;
}

export interface IDevicePaymentValue {
  isTransactEnabled: true;
  isMixedPaymentEnabled: true;
  isInstallmentEnabled: true;
  isShowChangeMoneyEnabled: true;
  isReceiptPrintingEnabled: true;
  isPaymentDivisorEnabled: true;
  isRefundEnabled: true;
  maximunDiscount: 0;
  interestRate: IInterestRateConfiguration;
  serviceFee: IServiceFeeConfiguration;
  methods: IDevicePaymentMethodes[];
  customMethods: IDevicePaymentMethodes[];
}

export interface IDevicePaymentMethodes {
  enabled: boolean;
  type: PaymentType; //paymentEnum
  name: string;
}

export enum PaymentType {
  UNINFORMED = 0,
  CREDIT = 1,
  DEBIT = 2,
  MONEY = 3,
  CASHLESS = 4,
  MEEPCOIN = 5,
  MIXED = 6,
  CHEQUE = 7,
  PENDING = 8,
  BOLETO = 9,
  REEMBOLSO = 10,
  CASHBACK = 11,
  PRODUCAO = 12,
  AJUSTE = 13,
  VOUCHER = 14,
  DELIVERY = 15,
  PERDA = 16,
  PIX = 18,
  WALLET = 22,
  OTHER1 = 101,
  OTHER2 = 102,
  OTHER3 = 103,
  OTHER4 = 104,
  OTHER5 = 105,
  OTHER6 = 106,
  OTHER7 = 107,
  OTHER8 = 108,
  OTHER9 = 109,
  OTHER10 = 110,
  OTHER11 = 111,
  OTHER12 = 112,
  OTHER13 = 113,
  OTHER14 = 114,
  OTHER15 = 115,
  OTHER16 = 116,
  OTHER17 = 117,
  OTHER18 = 118,
  OTHER19 = 119,
  OTHER20 = 120,
  OTHER21 = 121,
  OTHER22 = 122,
  OTHER23 = 123,
  OTHER24 = 124,
  OTHER25 = 125,
  OTHER26 = 126,
  OTHER27 = 127,
  OTHER28 = 128,
  OTHER29 = 129,
  OTHER30 = 130,
}

export interface IServiceFeeConfiguration {
  enabled: boolean;
  value: number;
}

export interface IInterestRateConfiguration {
  enabled: boolean;
  value: number;
}
